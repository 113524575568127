// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOT_ENTRIES = '/entries';
const ROOT_NOTES = '/notes';
const ROOT_PRODUCTS = '/products';
const ROOT_CMS = '/cms';
const ROOT_CATEGORIES = '/categories';
const ROOT_PORTAL = '/portal';

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: '/',
  entries: {
    root: ROOT_ENTRIES,
    list: path(ROOT_ENTRIES, '/list'),
    analytics: path(ROOT_ENTRIES, '/analytics'),
  },
  products: {
    root: ROOT_PRODUCTS,
    new: path(ROOT_PRODUCTS, '/new'),
    list: path(ROOT_PRODUCTS, '/list'),
  },
  cms: {
    root: ROOT_CMS,
    top: path(ROOT_CMS, '/top'),
    list: path(ROOT_CMS, '/list'),
    locals: path(ROOT_CMS, '/locals'),
  },
  portal: {
    root: ROOT_PORTAL,
    settings: path(ROOT_PORTAL, '/settings'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

const ROOT_API = '/api';
const API_PRODUCTS = ROOT_API + ROOT_PRODUCTS;
const API_ENTRIES = ROOT_API + ROOT_ENTRIES;
const API_NOTES = ROOT_API + ROOT_NOTES;
const API_CMS = ROOT_API + ROOT_CMS;
const API_CATEGORIES = API_PRODUCTS + ROOT_CATEGORIES;
export const PATH_API = {
  root: ROOT_API,
  products: {
    root: API_PRODUCTS,
    new: path(API_PRODUCTS, '/new'),
    edit: path(API_PRODUCTS, '/edit'),
    detail: path(API_PRODUCTS, '/detail'),
    draft: path(API_PRODUCTS, '/draft'),
    review: path(API_PRODUCTS, '/review'),
    reviews: path(API_PRODUCTS, '/reviews'),
    categories: path(API_PRODUCTS, '/categories'),
  },
  categories: {
    root: API_CATEGORIES,
    new: path(API_CATEGORIES, '/new'),
  },
  notes: {
    root: API_NOTES,
    new: path(API_NOTES, '/new'),
  },
  entries: {
    root: API_ENTRIES,
    analytics: path(API_ENTRIES, '/analytics'),
    cencel: path(API_ENTRIES, '/cencel'),
    edit: path(API_ENTRIES, '/edit'),
  },
  cms: {
    root: API_CMS,
    new: path(API_CMS, '/new'),
  },
};
