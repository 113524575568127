import {
  Home,
  HandCoinOutline,
  GiftOpenOutline,
  SitemapOutline,
  PaletteOutline,
} from 'mdi-material-ui';

import { hasPermission, PageConfig } from '@hui/components';
import { ConfigContextProps, NavConfig } from '@hui/contexts';

import { PATH_DASHBOARD } from './routes';

export interface IAuth0Keys {
  clientId: string;
  domain: string;
  redirectUrl: string;
  audience: string;
}

// API
// ----------------------------------------------------------------------
export const AUTH0_API: IAuth0Keys = {
  clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '',
  domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN || '',
  redirectUrl: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URL || '',
  audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE || '',
};

export const MUI = {
  pro: process.env.NEXT_PUBLIC_MUI_KEY,
};

const onDev = process.env.NODE_ENV === 'development';

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 0,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 0,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export type PageConfigs = Record<string, PageConfig>;

export const PAGES: Record<string, PageConfigs> = {
  ENTRIES: {
    LIST: {
      title: '寄付履歴一覧',
      path: PATH_DASHBOARD.entries.list,
      permission: '',
    },
    ANALYTICS: {
      title: '寄附申込み分析',
      path: PATH_DASHBOARD.entries.analytics,
      permission: '',
    },
  },
  PRODUCTS: {
    NEW: {
      title: '返礼品新規作成',
      path: PATH_DASHBOARD.products.new,
      permission: '',
    },
    LIST: {
      title: '返礼品一覧',
      path: PATH_DASHBOARD.products.list,
      permission: '',
    },
  },
  CMS: {
    LIST: {
      title: 'ページコンテンツ管理',
      path: PATH_DASHBOARD.cms.list,
      permission: '',
    },
    TOP: {
      title: 'トップページ構築',
      path: PATH_DASHBOARD.cms.top,
      permission: '',
    },
  },
  PORTAL: {
    SETTINGS: {
      title: 'ポータル設定',
      path: PATH_DASHBOARD.portal.settings,
      permission: '',
    },
  },
};

export const ROLES = {
  /** HARV運営 管理者 */
  SYSAD: 'harv/support/admin',
  /** HARV運営 サポートスタップ */
  SYSST: 'harv/support/staff',
  /** 運営代行者 マネージャー */
  AG_MGR: 'harv/agency/manager',
  /** 運営代行者 スタッフ */
  AG_STAFF: 'harv/agency/staff',
  /** 事業者 担当者 */
  MK_STAFF: 'harv/maker/staff',
  /** 自治体 担当者 */
  GV_STAFF: 'harv/govt/staff',
};

const navItems: NavConfig[] = [
  {
    subheader: '',
    showSubHeader: false,
    items: [
      {
        title: '寄附管理',
        icon: HandCoinOutline,
        path: PATH_DASHBOARD.entries.root,
        children: [PAGES.ENTRIES.LIST, PAGES.ENTRIES.ANALYTICS],
      },
      {
        title: '返礼品管理',
        icon: GiftOpenOutline,
        path: PATH_DASHBOARD.products.root,
        children: [PAGES.PRODUCTS.LIST, PAGES.PRODUCTS.NEW],
      },
      {
        title: 'サイトコンテンツ管理',
        icon: PaletteOutline,
        path: PATH_DASHBOARD.cms.root,
        children: [PAGES.CMS.LIST, PAGES.CMS.TOP],
      },
      {
        title: 'ポータル設定',
        icon: SitemapOutline,
        path: PATH_DASHBOARD.portal.root,
        children: [PAGES.PORTAL.SETTINGS],
      },
    ],
  },
];

export const APP_CONFIG: ConfigContextProps = {
  values: { public_storage: process.env.NEXT_PUBLIC_HARV_PUBLIC_STORAGE },
  // Header User Account Menu
  // ----------------------------------------------------------------------
  headerAccount: {
    menuOptions: [
      {
        label: 'ホーム',
        linkTo: '/',
        icon: Home,
      },
    ],
  },
  // Side Navigation Bar Menu
  // ----------------------------------------------------------------------
  navItems,
  getNavItems: (userPermits: string[]) =>
    navItems.map((group: NavConfig) => {
      if (group.subheader === 'development' && !onDev) {
        return {
          ...group,
          items: [],
        };
      }

      const firstLevelFiltered = group.items.filter((item) =>
        hasPermission(item.permission, userPermits)
      );

      // console.log('firstLevelFiltered', firstLevelFiltered);

      const items = firstLevelFiltered.map((item) => {
        // console.log(item.title, 'hasChildren:', !!item.children);

        if (!item.children) {
          return item;
        }

        const filteredChildren = item.children.filter((item) =>
          hasPermission(item.permission, userPermits)
        );

        if (filteredChildren.length === 0) {
          return {
            ...item,
            disabled: true,
          };
        }

        return {
          ...item,
          children: filteredChildren,
        };
      });

      return {
        ...group,
        items,
      };
    }),
  roles: ROLES,
};
