import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { forwardRef, ReactNode, useEffect } from 'react';

import { motion, useAnimationControls } from 'framer-motion';
import { useSnackbar } from 'notistack';

import { Container, ContainerProps, Box, BoxProps, Stack, StackProps } from '@mui/material';

import { PermitGuard } from '..';
import { useAccess } from '../../hooks';
import { varFade } from '../elements/animate';
import { PageHeader, PageHeaderProps } from '../parts';

const animPageTransition = varFade({
  distance: 100,
  durationIn: 0.3,
  durationOut: 0.3,
}).inLeft;

// ----------------------------------------------------------------------

export interface PageConfig {
  title: string;
  path: string;
  permission: string;
  allowRef?: string[];
  refRejectMsg?: string;
}

interface PageProps {
  children: ReactNode;
  pageConfig: PageConfig;
  title?: string;
  meta?: ReactNode;
  BoxProps?: BoxProps;
  ContainerProps?: ContainerProps;
  needWrapStack?: boolean;
  PageHeaderPros?: PageHeaderProps;
  StackProps?: StackProps;
}

type WrapPermitProps = Pick<PageProps, 'children' | 'pageConfig'>;

function WrapPermit({ children, pageConfig }: WrapPermitProps) {
  return pageConfig?.permission ? (
    <PermitGuard {...{ hasContent: true, permission: pageConfig?.permission }}>
      {children}
    </PermitGuard>
  ) : (
    <>{children}</>
  );
}

interface WrapStackProps extends StackProps {
  needWrapStack?: boolean;
}

function WrapStack({ children, needWrapStack, ...other }: WrapStackProps) {
  return needWrapStack ? <Stack {...other}>{children}</Stack> : <>{children}</>;
}

const Page = forwardRef<HTMLDivElement, PageProps>(
  (
    {
      children,
      pageConfig,
      title,
      meta,
      BoxProps,
      ContainerProps,
      PageHeaderPros,
      needWrapStack = true,
      StackProps = { spacing: { xs: 3, md: 6 } },
    },
    ref
  ) => {
    const ON_DEV = process.env.NODE_ENV === 'development';

    const controls = useAnimationControls();
    const router = useRouter();
    const { history } = useAccess();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      controls.start('animate');
      router.events.on('routeChangeStart', () => controls.start('exit'));

      const { allowRef, refRejectMsg } = pageConfig;
      const referer = history[0]?.replace(/\/$/, ''); // 比較で一致するようにパスの最後のスラッシュを取り除く

      if (!ON_DEV && allowRef && !allowRef.includes(referer)) {
        router.push(allowRef[0]);
        enqueueSnackbar(refRejectMsg || '所定のページよりアクセスしてください', {
          variant: 'error',
        });
      }

      return () => {
        router.events.off('routeChangeStart', () => controls.start('exit'));
      };
    }, []);

    const _title = title ? title : pageConfig.title;

    return (
      <>
        <Head>
          <title>{`${_title} | HARV`}</title>
          {meta}
        </Head>

        <Box
          {...(!ON_DEV && {
            component: motion.div,
            variants: animPageTransition,
            initial: 'initial',
            animate: controls,
            exit: 'exit',
            transition: { type: 'linear' },
            ref: ref,
          })}
          {...BoxProps}
          sx={{ minHeight: '100vh' }}
        >
          <WrapPermit pageConfig={pageConfig}>
            <Container {...ContainerProps}>
              <PageHeader {...PageHeaderPros} heading={_title} />
              <WrapStack needWrapStack={needWrapStack} {...StackProps}>
                {children}
              </WrapStack>
            </Container>
          </WrapPermit>
        </Box>
      </>
    );
  }
);

export default Page;
