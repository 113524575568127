import { format, parse, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'yyyy年MM月dd日');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'yyyy年MM月dd日 hh:mm');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'yyyy/MM/dd p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fDateKey(date: Date | string | number) {
  return format(new Date(date), 'yyyyMMdd');
}

export function pDateKey(date: string) {
  return parse(date, 'yyyyMMdd', new Date());
}

export function fTimeKey(date: Date | string | number) {
  return format(new Date(date), 'Hmm');
}

export function pDateTimeKey(date: string) {
  return parse(date, 'yyyyMMddHmm', new Date());
}
