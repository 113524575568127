import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useAuth } from '@hui/hooks';

import { PATH_DASHBOARD } from '@/routes';

export default function Index() {
  const router = useRouter();
  const { user } = useAuth();

  useEffect(() => {
    console.log(user);
    router.push(PATH_DASHBOARD.products.new);
  }, [user]);

  return <></>;
}
